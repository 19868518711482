// 弹出层组件css样式
.statePopup {
  width: 134px;
  color: rgba($color: #fff, $alpha: 0.8);
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 14px;
      font-size: 14px;
      font-family: OPPOSans-R, OPPOSans;
      font-weight: normal;
      color: rgba($color: #fff, $alpha: 0.8);
      line-height: 14px;

      .tag {
        display: inline-block;
        margin-right: 8px;
        width: 8px;
        height: 4px;
      }

      .number {
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.8);
      }
    }
  }
  .line {
    margin: 14px 0;
    width: 100%;
    border: 1px dashed #b2b2b2;
  }
}
