.login_box {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .form_box {
    width: 320px;
    height: 220px;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0 1px 10px 1px #c2bfbf;

    .ant-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .switch_button {
      width: 100%;
      margin-top: 12px;
      font-size: 14px;
      font-family: "OPPOSans-M";
      color: #555555;
      text-align: right;
      cursor: pointer;
    }

    .modal_input {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .phone_box {
      .input_box {
        width: 100%;
        height: 32px;
        margin-bottom: 24px;
        border: 1px solid #d9d9d9;
        display: flex;
        align-items: center;

        .send_button {
          width: 38px;
          height: 32px;
          font-size: 14px;
          font-family: "OPPOSans-M";
          color: #555555;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
  }
}