.pt-table {
  width: 100%;

  &__head {
    padding: 10px 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    background: rgba(67, 121, 159, 0.5);
    display: flex;

    &>div {
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: rgba($color: #fff, $alpha: 0.6);
    }
  }

  &__content {
    height: 542px;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      border-radius: 6px;
      background: rgba($color: #fff, $alpha: 0.05);
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 6px;
      background: #F0FDFF;
    }

    &__line {
      padding: 10px 16px;
      margin-bottom: 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      &:hover {
        padding: 9px 15px;
        border: 1px solid #05c97d;
      }

      &>div {
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.8);
      }

      &:nth-child(odd) {
        background: rgba(67, 121, 159, 0.5);
      }

      &:nth-child(even) {
        background: rgba(52, 97, 129, 0.5);
      }
    }
  }

  &__check {
    width: 22px;
    height: 22px;
    border: 1px solid #55585d;
    margin-right: 15px;
    border-radius: 50%;
    cursor: pointer;
  }

  .icon-gouxuan {
    width: 22px;
    height: 22px;
    margin-right: 15px;
    line-height: 22px;
    font-size: 22px;
    color: #05c97d;
    cursor: pointer;
  }
}