.assets-skin {
  &--left {
    &__suffix {
      padding: 4px 10px !important;
      background: rgba(3, 38, 103, 0.8);
      border-radius: 2px;
      border: 1px solid #207DDB;
      display: flex;

      .pt-icon {
        color: rgba($color: #fff, $alpha: 0.8);
        margin-right: 10px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--right {
    &__suffix {
      display: flex;
      align-items: center;

      .icon-shoucang1,
      .icon-shoucang2 {
        cursor: pointer;
      }

      .icon-shoucang2 {
        color: #ffb412;
      }

      .suffix__tag {
        width: 44px;
        height: 20px;
        line-height: 20px;
        border-radius: 2px;
        margin-left: 14px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        text-align: center;
      }

      .normal {
        background: #02d281;
        box-shadow: 0px 0px 6px 0px rgba($color: #02d281, $alpha: 0.7);
      }

      .abnormal {
        background: #ff3d3d;
        box-shadow: 0px 0px 6px 0px rgba($color: #ff3d3d, $alpha: 0.7);
      }
    }
  }
}

.expand {
  &__box {
    position: fixed;
    right: 447px;
    top: 135px;
    width: 971px;
    height: 836px;

    &>div {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .expand__button {
      position: absolute;
      right: 971px;
      top: 104px;

      &>div {
        transform: rotate(0);
      }
    }
  }

  &__content {
    position: absolute;
    right: 17px;
    top: 0;
    width: 954px;
    height: 836px;
    background: rgba(18, 23, 34, 0.82);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    border: 1px solid #000000;
    padding: 18px 14px 14px;

    &__head {
      font-size: 24px;
      font-family: OPPOSans-M;
      font-weight: normal;
      color: #fff;
      margin-bottom: 20px;
    }

    &__select {
      width: 100%;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select--right {
        min-width: 468px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-picker {
          border: 1px solid #6d7278;
          background: rgba(29, 34, 44, 0.6);
          margin: 0 4px;

          input {
            height: 18px;
            color: rgba($color: #fff, $alpha: 0.8);
          }

          .ant-picker-suffix {
            color: #d8d8d8;
          }

          .ant-picker-clear {
            background: #00000000;
            color: #d8d8d8;
          }
        }
      }
    }

    &__background {
      padding: 24px;
      margin-bottom: 4px;
      background: rgba($color: #1d222c, $alpha: 0.6);
    }

    &__hint {
      padding: 19px 24px 24px;
      background: rgba($color: #1d222c, $alpha: 0.6);

      .hint__head {
        width: 100%;
        height: 20px;
        margin-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--left {
          font-size: 20px;
          font-family: OPPOSans-M;
          font-weight: normal;
          color: #fff;
        }

        &--right {
          display: flex;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: rgba($color: #fff, $alpha: 0.8);

          &>div {
            margin-right: 12px;
            display: flex;
            align-items: center;

            .legend {
              width: 12px;
              height: 6px;
              border-radius: 4px;
              margin-right: 4px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &__button {
    position: fixed;
    right: 447px;
    top: 240px;
    transform: translateY(-50%);
    width: 26px;
    height: 34px;
    background: rgba(10, 41, 97, 0.8);
    // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #207DDB;
    border-right: none;
    z-index: 6;

    .pt-icon {
      width: 8px;
      height: 14px;
      line-height: 14px;
      font-size: 14px;
    }

    &>div {
      position: relative;
      width: 100%;
      height: 100%;
      transform: rotate(180deg);

      .jiantou1,
      .jiantou2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
      }

      .jiantou1 {
        right: 8px;
        opacity: 0.6;
      }

      .jiantou2 {
        right: 14px;
      }
    }
  }
}