.icon-legend__list {
  margin-bottom: 7px;

  .list__children {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      margin-bottom: 8px;

      .color--blue1 {
        color: #04CBC8;
      }

      .color--blue2 {
        color: #1165FE;
      }
    }

    .pt-icon-legend {
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 100%;
        justify-content: space-around;
      }
    }



    .alert-card {
      width: 89px;
      height: 56px;
      border-radius: 2px;
      background: rgba($color: #43799F, $alpha: 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      .village-card__content {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba($color: #fff, $alpha: 0.8);
        font-size: 14px;
        font-family: OPPOSans-R;

        .bg--blue1 {
          background: #04CBC8;
        }

        .bg--blue2 {
          background: #1165FE;
        }

        &>div {
          &:first-child {
            width: 86px;
            text-align: right;
          }

          &:last-child {
            width: 122.12px;
            height: 3px;
            border-radius: 3px;
          }
        }
      }

      &>span {
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
      }
    }

    .special1 {
      padding: 0 8px 0 12px;

      &>span {
        color: #FFE400;
      }
    }

    .special2 {
      padding: 0 8px 0 12px;

      &>span {
        color: #ff4545;
      }
    }


  }
}

.right__suffix {
  display: flex;
  align-items: center;

  &__tag {
    height: 20px;
    padding: 0 8px;
    margin-left: 14px;
    border-radius: 2px;
    color: #000;
    font-family: OPPOSans-R;
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .normal {
    background: #02d281;
    box-shadow: 0px 0px 6px 0px rgba(56, 234, 165, 0.7);
  }

  .abnormal {
    background: #FFE400;
    box-shadow: 0px 0px 6px 0px rgba(255, 228, 0, 0.7);
  }
}

.expand__box {
  position: fixed;
  right: 447px;
  top: 135px;
  width: 971px;
  height: 836px;
  z-index: 4;

  &>div {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .expand__button {
    position: absolute;
    right: 971px;
    top: 104px;

    &>div {
      transform: rotate(0);
    }
  }

  .expand__content {
    position: absolute;
    right: 17px;
    top: 0;
    width: 954px;
    height: 836px;
    background: rgba(10, 41, 97, 0.8);
    border: 1px solid #0352A2;
    padding: 18px 14px 14px;

    &__head {
      font-size: 24px;
      font-family: OPPOSans-M;
      font-weight: normal;
      color: #fff;
      margin-bottom: 20px;
    }

    &__select {
      width: 100%;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select--right {
        min-width: 468px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-picker {
          border: 1px solid #207DDB;
          background: rgba(3, 38, 103, 0.8);
          margin: 0 4px;

          input {
            height: 18px;
            color: rgba($color: #fff, $alpha: 1);
          }

          .ant-picker-suffix {
            color: #d8d8d8;
          }

          .ant-picker-clear {
            background: #00000000;
            color: #d8d8d8;
          }
        }
      }
    }

    &__background {
      padding: 24px;
      margin-bottom: 4px;
      background: rgba(67, 121, 159, 0.5);
    }

    &__hint {
      padding: 19px 24px 24px;
      background: rgba(67, 121, 159, 0.5);

      .hint__head {
        width: 100%;
        height: 20px;
        margin-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--left {
          font-size: 20px;
          font-family: OPPOSans-M;
          font-weight: normal;
          color: #fff;
        }

        &--right {
          display: flex;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: rgba($color: #fff, $alpha: 0.8);

          &>div {
            margin-right: 12px;
            display: flex;
            align-items: center;

            .legend {
              width: 12px;
              height: 6px;
              border-radius: 4px;
              margin-right: 4px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.village-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #2D6BBE, $alpha: 0.35);
  pointer-events: none;
  z-index: 2;
}

#modifyDirection {
  background: rgba(10, 41, 97, 0.8);
  color:#fff;
  padding: 0 4px;
  cursor: pointer;
}