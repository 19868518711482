.inspection_table {
  padding: 20px;
  .ant-table-tbody > tr:hover > td {
    background-color: transparent !important; /* 移除悬浮背景色 */
  }
  
  .ant-table {
    background-color: transparent;
    border: 1px solid #22519C;
    .ant-table-thead {
      background-color: transparent;
    }
  }
  .ant-table-thead > tr > th {
    background-color: transparent;
    color: hsla(0, 0%, 100%, .6);
    border-bottom: 1px solid rgba(10, 41, 97, .8);
  }
  .ant-table-thead > tr > th::before {
    width: 0 !important;
  }
  .ant-table-cell-scrollbar {
    display: none;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(10, 41, 97, .8);
    border-right: 1px solid rgba(10, 41, 97, .8);
    color: #fff;
    font-size: 14px;
    font-family: OPPOSans-M;
  }
  .ant-table-tbody > tr {
    background: #30507b;
  }
  .ant-table-tbody > tr:nth-child(odd){
    background: #29446c;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      border-radius: 6px;
      background: rgba($color: #fff, $alpha: 0.05);
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 6px;
      background: #F0FDFF;
    }
  }

  
}