.pt-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 68px;
  padding: 13px 24px;
  background: url('../../assets/img/headbackground.png') no-repeat;
  background-size: 100% 100%;
  z-index: 4;

  &>div {
    position: relative;

    .header {
      &__left {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;

        .pt__title__suffix {
          display: flex;

          &--left {
            padding: 5px 10px;
            height: 28px;
            line-height: 20px;
            font-family: OPPOSans-R;
            font-weight: normal;
            font-size: 18px;
            color: #000;
            text-align: center;
            background: #50EDFF;
            border-radius: 4px 0 0 4px;
          }

          &--right {
            min-width: 95px;
            height: 28px;
            border-radius: 0 4px 4px 0;
            border: 1px solid #25B5FF;
            border-left: none;
            position: relative;

            .pt__title__text {
              width: 100%;
              height: 100%;
              padding: 0 5px;
              background: #00000000;
              border-radius: 0 4px 4px 0;
              color: #fff;
              font-size: 18px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              letter-spacing: 1px;
              cursor: pointer;

              .icon-jiantou2 {
                font-size: 12px;
                transform: rotateZ(90deg);
                transition: 0.3s;
              }

              .right--active {
                transform: rotateZ(-90deg);
              }
            }

            .pt__title__list {
              position: absolute;
              bottom: -200px;
              width: 130px;
              height: 200px;
              background: rgba(3, 38, 103, 0.8);
              overflow-y: auto;

              .list__text {
                width: 100%;
                height: 30px;
                padding: 0 5px;
                color: #fff;
                text-align: center;
                line-height: 30px;
                cursor: pointer;

                &:hover {
                  background: rgba($color: #00ffcb, $alpha: 0.15);
                }
              }

              .list--active {
                background-color: rgba($color: #02d281, $alpha: 1);
                color: #000;

                &:hover {
                  background-color: rgba($color: #02d281, $alpha: 1);
                }
              }

              &::-webkit-scrollbar {
                width: 6px;
              }

              &::-webkit-scrollbar-track {
                width: 6px;
                border-radius: 6px;
                background: rgba($color: #fff, $alpha: 0.05);
              }

              &::-webkit-scrollbar-thumb {
                width: 6px;
                border-radius: 6px;
                background: #F0FDFF;
              }
            }

            .ant-select {
              width: 100%;
              height: 100%;
              color: #fff;

              .ant-select-selection-item {
                padding-left: 0 !important;
                padding-right: 0 !important;
              }

              .ant-select-arrow {
                color: #d8d8d8;
                right: 8px;
              }

              .ant-select-dropdown {
                background: rgba(3, 38, 103, 0.8);

                .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                  background-color: rgba($color: #02d281, $alpha: 1);
                }

                .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
                  background: rgba($color: #00ffcb, $alpha: 0.15);
                }

                .ant-select-item {
                  color: #fff;
                }
              }
            }
          }
        }

        .weather {
          height: 22px;
          font-size: 22px;
          font-family: HYYakuHei;
          font-weight: normal;
          color: #fff;
          line-height: 22px;
        }

        .ant-divider {
          border-left: 1px solid rgba(255, 255, 255, 0.15)
        }
      }

      &__content {
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-size: 36px;
        font-family: 'FZShangKJW';
        font-weight: normal;
        color: #fff;
        letter-spacing: 3px;
      }

      &__right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

}

.list_children_box {
  height: 260px;
  overflow: hidden auto;
  .ant-checkbox-wrapper {
    display: flex;
    color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: #F0FDFF;
  }

}