@import "~antd/dist/antd.css";

@font-face {
  font-family: "FZShangKJW";
  src: url("./assets/font/FZShangKJW.TTF");
}

@font-face {
  font-family: "OPPOSans-R";
  src: url("./assets/font/OPPOSans R.ttf");
}

@font-face {
  font-family: "OPPOSans-B";
  src: url("./assets/font/OPPOSans B.ttf");
}

@font-face {
  font-family: "OPPOSans-H";
  src: url("./assets/font/OPPOSans H.ttf");
}

@font-face {
  font-family: "OPPOSans-M";
  src: url("./assets/font/OPPOSans M.ttf");
}

@font-face {
  font-family: "HYYakuHei";
  src: url("./assets/font/HYYakuHei.ttf");
}

@font-face {
  font-family: "HYYakuHei55W";
  src: url("./assets/font/HYYakuHei55W.ttf");
}

.ant-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 6px;
    background: rgba(3, 38, 103, 0.8);
    border: none;
    box-shadow: none;
  }

  .list__children {
    width: 100px;
    height: 30px;
    padding: 0 5px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;

    &:hover {
      background: rgba($color: #00ffcb, $alpha: 0.15);
    }

    &--active {
      background-color: rgba($color: #02d281, $alpha: 1);
      color: #000;

      &:hover {
        background-color: rgba($color: #02d281, $alpha: 1);
      }
    }
  }
}


//自定义滚动条

.customScrollBars {
  overflow: hidden auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #F0FDFF;

  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }
}