.emergency1_box {
  .emergency1_left_list {
    .general_list {
      color: #fff;
      cursor: pointer;

      &:hover {
        color: #000;
        background: #5FCF88;
      }
    }

    .active_list {
      color: #000;
      background: #5FCF88 !important;
    }
  }

  .iframe_box {
    width: 100%;
    height: 99.5%;

    iframe {
      width: 100%;
      height: 100%;
      border: 0px;
    }
  }
}