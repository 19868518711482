$state-info-width: 220px;

$townName-width: 68px;
$townName-height: 18px;
$state-tag-width: 8px;
$state-tag-height: 4px;
$state-tag-ml: 2px;

$pump-tag-width: 4px;
$pump-tag-height: 4px;

.pt-station-state__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  padding-bottom: 6px;
}

.pt-main-skin {

  .project_profile {
    width: 100%;

    &>div {
      color: #ffffffcc;
      font-family: OPPOSans-R;
      text-indent: 28px;
    }
  }

  .simple_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; // 在第几行显示
    -webkit-box-orient: vertical;
    cursor: pointer;
  }

  .pt-station-state {
    width: 100%;

    &__body {
      max-height: 192px;
      overflow: hidden auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        width: 6px;
        border-radius: 6px;
        background: rgba($color: #fff, $alpha: 0.05);
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 6px;
        background: #F0FDFF;
      }


      .townInfo {
        display: flex;
        margin-bottom: 10px;

        .townName {
          width: $townName-width;
          height: $townName-height;
          line-height: $townName-height;
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: #ffffff;
          text-align: center;
          background: rgba(67, 121, 159, 0.5);
        }

        .stateInfo {
          position: relative;
          width: $state-info-width;
          height: 18px;
          background: rgba(67, 121, 159, 0.5);
          margin-left: 2px;
          padding: 0 6px;
          display: flex;
          justify-content: center;
          align-items: center;

          &::after {
            position: absolute;
            width: calc(100% - 12px);
            height: 5px;
            left: 6px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            animation: mymove 1.2s linear infinite;
            -webkit-animation: mymove 1.2s linear infinite;
          }

          @keyframes mymove {
            0% {
              width: 0;
              background: rgba($color: #fff, $alpha: 0.5);
            }

            100% {
              width: 100%;
              background: rgba($color: #fff, $alpha: 0);
            }
          }

          @-webkit-keyframes mymove {
            0% {
              width: 0;
              background: rgba($color: #fff, $alpha: 0.5);
            }

            100% {
              width: 100%;
              background: rgba($color: #fff, $alpha: 0);
            }
          }
        }

        .text_box {
          width: calc(100% - 290px);
          height: 18px;
          margin-left: 2px;
          background: rgba(67, 121, 159, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .legend {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 18px;

      .legendInfo {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 0.6);

        .legendBox {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .legendTitle {
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
        }

        .legendTag {
          margin: 0 1px 0 2px;
          width: 8px;
          height: 4px;
        }

        .legendTagName {
          font-family: OPPOSans-R;
          font-weight: normal;
        }
      }
    }

  }

  .pt-pumpstation {
    &__body {
      max-height: 192px;
      overflow: hidden auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        width: 6px;
        border-radius: 6px;
        background: rgba($color: #fff, $alpha: 0.05);
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 6px;
        background: #F0FDFF;
      }


      .townInfo {
        display: flex;
        margin-bottom: 10px;

        .townName {
          width: $townName-width;
          height: $townName-height;
          line-height: $townName-height;
          font-family: OPPOSans-R;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          background: rgba(67, 121, 159, 0.5);
        }

        .stateInfo {
          position: relative;
          width: $state-info-width;
          height: 18px;
          background: rgba(67, 121, 159, 0.5);
          margin-left: 2px;
          padding: 0 6px;
          display: flex;
          justify-content: center;
          align-items: center;

          &::after {
            position: absolute;
            width: calc(100% - 12px);
            height: 5px;
            left: 6px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            animation: mymove 1.2s linear infinite;
            -webkit-animation: mymove 1.2s linear infinite;
          }

          @keyframes mymove {
            0% {
              width: 0;
              background: rgba($color: #fff, $alpha: 0.5);
            }

            100% {
              width: 100%;
              background: rgba($color: #fff, $alpha: 0);
            }
          }

          @-webkit-keyframes mymove {
            0% {
              width: 0;
              background: rgba($color: #fff, $alpha: 0.5);
            }

            100% {
              width: 100%;
              background: rgba($color: #fff, $alpha: 0);
            }
          }


        }

        .text_box {
          width: calc(100% - 290px);
          height: 18px;
          margin-left: 2px;
          background: rgba(67, 121, 159, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

    }

    .legend {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 18px;

      .legendInfo {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 0.6);

        .legendBox {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .legendTitle {
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
        }

        .legendTag {
          margin: 0 1px 0 2px;
          width: 8px;
          height: 4px;
        }

        .legendTagName {
          font-family: OPPOSans-R;
          font-weight: normal;
        }
      }
    }
  }

  .main_skin_modal {
    .project_profile {
      width: 100%;
      height: 100%;
      padding: 25px;
      overflow: hidden auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        width: 6px;
        border-radius: 6px;
        background: rgba($color: #fff, $alpha: 0.05);
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 6px;
        background: #F0FDFF;
      }

      &>img {
        width: 100%;
        margin-bottom: 10px;
      }

      &>div {
        font-size: 18px;
        line-height: 35px;
      }
    }
  }
}

.date_type {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
  .ant-picker {
    border: 1px solid #207DDB;
    background: rgba(3, 38, 103, 0.8);
    .ant-picker-input > input  , .anticon {
      color: #fff;
    }
    
  }

}