.stations {
  &__legend {
    .icon-legend__list {
      margin-bottom: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .alert-card {
        width: 121px;
        height: 56px;
        border-radius: 2px;
        background: rgba($color: #43799F, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        &>span {
          margin-right: 10px;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: #ff4545;
        }
      }
    }

    .color-legend__list {
      width: 100%;
      height: 33px;
      padding: 0 13px;
      background: rgba($color: #43799F, $alpha: 0.5);
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__list {
    &__menu {
      width: 100%;

      .menu-item {
        width: 100%;
        min-height: 30px;
        padding: 0 10px 0 6px;
        margin-bottom: 1px;
        background: rgba(34, 39, 49, 0.6);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &--left {
          color: rgba($color: #fff, $alpha: 0.8);
          display: flex;
          align-items: center;

          .pt-icon {
            margin-right: 4px;
            color: rgba($color: #fff, $alpha: 0.8);
          }
        }

        &--right {
          color: rgba($color: #fff, $alpha: 0.6);
        }

        &--left,
        &--right {
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
        }
      }
    }

    .test__list {
      &>div {
        width: 100%;
        height: 30px;
        padding: 0 10px;
        margin-bottom: 1px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:nth-child(odd) {
          background: rgba(29, 34, 44, 0.6);
        }

        &:nth-child(even) {
          background: rgba(35, 42, 57, 0.6);
        }
      }

      .test__active {
        color: #000;
        background: #05c97d !important;
      }
    }
  }
}

.collapse_box {
  max-height: 650px;
}
.box_success , .box_warning , .box_error{
  min-width: 44px;
  height: 20px;
  padding: 0 4px;
  border-radius: 2px;
  border: 1px solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  white-space: nowrap;
  background: rgba(95, 207, 136, 0.15);
  border-color: #5FCF88;
}

.box_warning {
  background: rgba(247, 203, 125, 0.15);
  border-color: #F7CB7D;
}

.box_error {
  background: rgba(255, 58, 58, 0.15);
  border-color: #ff3a3a;
}
.sub_content_title {
  padding-left: 14px;
  margin-top: 4px;
}

.chart_list {
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden auto;


  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: #F0FDFF;
  }

  .chart_box {
    width: 49%;
    margin-bottom: 10px;

    .chart_text {
      width: 100%;
      height: 22px;
      font-size: 14px;
      font-family: OPPOSans-R;
      color: rgba($color: #fff, $alpha: 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.stations_table {
  width: 100%;

  &_head {
    &>tr {
      width: 100%;

      background: rgba(67, 121, 159, 0.5);


      &>th {
        padding: 5px 10px;
        font-size: 14px;
        font-family: OPPOSans-R;
        color: #fff;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  &_body {
    &>tr {
      width: 100%;
      background: rgba(67, 121, 159, 0.5);
      border-bottom: 1px solid #ffffff00;

      &:nth-child(odd) {
        background: rgba(63, 96, 126, 0.5)
      }

      &>td {
        padding: 5px 10px;
        font-size: 14px;
        font-family: OPPOSans-R;
        color: #fff;
        font-weight: 400;

      }
    }
  }

  &_unit {
    font-size: 12px;
    color: rgba($color: #fff, $alpha: 0.6);
  }
}

.details_table {
  width: 100%;
  height: 668px;

  .ant-table {
    background: #ffffff00;

    .ant-table-header {
      margin-bottom: 2px;

      .ant-table-thead {
        &>tr>th {
          font-size: 14px;
          font-family: OPPOSans-R;
          color: rgba(255, 255, 255, 0.8);
          background: rgba(67, 121, 159, 0.5);
          border-bottom: 0px solid rgba(67, 121, 159, 0);
          backdrop-filter: blur(3px);

          &::before {
            background-color: #ffffff00 !important;
          }
        }

        .ant-table-cell-scrollbar {
          box-shadow: 0 0 0 0
        }
      }
    }

    .ant-table-body {
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: rgba($color: #fff, $alpha: 0.05);
      }

      &::-webkit-scrollbar-thumb {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: #F0FDFF;
      }

      .ant-table-tbody {
        &>tr>td {
          border-bottom: 0;
        }

        .ant-table-row {
          font-size: 14px;
          font-family: OPPOSans-R;
          color: rgba(255, 255, 255, 0.8);

          &:nth-child(odd) {
            background: rgba(67, 121, 159, 0.5);
          }

          &:nth-child(even) {
            background: rgba(52, 97, 129, 0.5);
          }

        }


        &>tr.ant-table-row:hover>td,
        .ant-table-cell-fix-left {
          background: #ffffff00;
          backdrop-filter: blur(3px);
        }

      }
    }
  }
}