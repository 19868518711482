.range_picker_box {
  width: 100%;
  height: 24px;

  background: rgba(3, 38, 103, 0.8);
  border-radius: 2px;
  border: 1px solid #207DDB;
  display: flex;
  align-items: center;



  .ant-picker {
    width: 100%;

    .ant-picker-input {
      input {
        color: white;
      }
    }
  }
}