.emergency3_box {
  .emergency3_left {

    .type_box {
      width: 100%;
      height: 24px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    .describe_text {
      width: 136.5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .emergency3_right {
    .general_list {
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;

      &>span {
        color: rgba($color: #fff, $alpha: 0.8);

        &:nth-child(even) {
          color: #fff;
        }
      }

      .image_box {
        width: 100%;

        .image_box_head {
          width: 100%;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &>span {
            color: rgba($color: #fff, $alpha: 0.8);

            &:nth-child(even) {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .modal_image_box {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
    }
  }
}