.operations_box {
  .operations_left {
    .collapse_box {
      width: 100%;
      max-height: 650px;
    }
  }

  .operations_right {
    .right_content {
      width: 100%;
      margin-top: 10px;

      .content_head {
        padding: 5px 7px;

        .pt-icon {
          color: #54A2F8;
          cursor: pointer;
        }
      }

      .content_body {
        width: 100%;
        max-height: 600px;

        .body_item {
          cursor: pointer;

          &:hover {
            .list_title {
              color: #000;
            }

            .state_box {
              color: #000;
              border-color: #000;
            }
          }

          .list_title {
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            font-family: OPPOSans-R;
            font-weight: normal;
            color: #fff;
          }
        }

        .hover_success {
          &:hover {
            background: #5FCF88;
          }
        }

        .hover_warning {
          &:hover {
            background: #F7CB7D;
          }
        }

        .active_success,
        .active_warning {
          .list_title {
            color: #000;
          }

          .state_box {
            color: #000;
            border-color: #000;
          }
        }

        .active_success {
          background: #5FCF88;
        }

        .active_warning {
          background: #F7CB7D;
        }
      }
    }
  }

  .show_time_box {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #22519D;
    font-size: 18px;
    font-family: OPPOSans-M;
    font-weight: normal;
    color: rgba($color: #fff, $alpha: 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .excel_box {
    width: 100%;
    height: calc(100% - 50px);
    padding: 16px 24px;

    .excel_content {
      width: 100%;
      border: 1px solid #3673d1;
      border-right: 0;
      border-bottom: 0;
      margin-bottom: 16px;
      background: rgba($color: #3F607E, $alpha: 0.35);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .excel_title,
    .excel_title2,
    .excel_text3,
    .excel_text4,
    .excel_state,
    .excel_text,
    .excel_text2 {
      width: 100%;
      height: 100%;
      padding: 11px 16px 12px;
      border: 1px solid #3673d1;
      border-top: 0px;
      border-left: 0px;
      font-size: 14px;
      font-weight: normal;
      color: #fff;
      display: flex;
      align-items: center;
    }

    .excel_image {
      width: 100%;
      padding: 11px 10px 12px;
      border: 1px solid #3673d1;
      border-top: 0px;
      border-left: 0px;
      font-size: 14px;
      font-weight: normal;
      font-family: OPPOSans-M;
      color: #fff;

      &>img {
        width: 300px;
        height: 300px;
        margin: 0 10px 10px;
      }
    }

    .excel_title,
    .excel_title2 {
      font-family: OPPOSans-R;
      color: rgba($color: #fff, $alpha: 0.6);
    }

    .excel_title2,
    .excel_text3,
    .excel_text4,
    .excel_state {
      justify-content: center;
    }

    .excel_text4 {
      font-family: OPPOSans-R;

    }


    .excel_text,
    .excel_text2,
    .excel_text3 {
      font-family: OPPOSans-M;
    }

    .excel_text2 {
      min-height: 176px;
      align-items: flex-start;
    }

    .excel_state {
      font-family: OPPOSans-B;
    }

  }

  .monitoring_box {
    width: 100%;
    height: 100%;
    background: #000000;
  }
}