.village-table {
  width: 100%;
  min-height: 70px;
  max-height: 680px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: #F0FDFF;
  }

  .table_content {
    width: 100%;
    min-height: 30px;
    margin-bottom: 2px;

    .content_title {
      width: 100%;
      height: 30px;
      padding: 8px 10px;
      margin-bottom: 2px;
      background: rgba($color: #43799F, $alpha: 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .title_left {
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-family: OPPOSans-M;
        font-weight: normal;
        color: #fff;

        span {
          font-family: OPPOSans-R;
          color: rgba($color: #fff, $alpha: 0.6);
        }
      }
    }

    .content_child_list {
      min-height: 6px;
      max-height: 300px;
      overflow: hidden auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        width: 6px;
        border-radius: 6px;
        background: rgba($color: #fff, $alpha: 0.05);
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 6px;
        background: #F0FDFF;
      }

      .content_child {
        width: 100%;
        height: 30px;
        padding: 6px 10px;
        margin-bottom: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:nth-child(odd) {
          background: rgba(67, 121, 159, 0.5);
        }

        &:nth-child(even) {
          background: rgba(52, 97, 129, 0.5);
        }

        .child_title {
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: #fff;

        }

        .child_state {
          width: 44px;
          height: 20px;
          line-height: 18px;
          border-radius: 2px;
          color: #fff;
          text-align: center;
          margin-left: 6px;
        }

        .normal {
          background: rgba(2, 210, 129, 0.15);
          border: 1px solid #02d281;
        }

        .abnormal {
          background: rgba(247, 203, 125, 0.15);
          border: 1px solid #F7CB7D;
        }
        .error  {
          background: rgba(255, 58, 58, 0.15);
          border:1px solid #ff3a3a;
        }
      }

      .active {
        &_normal {
          background: #05c97d !important;

          .child_state {
            color: #000 !important;
            background: none !important;
            border: 1px solid #000 !important;
          }
        }

        &_abnormal {
          background: #F7CB7D !important;

          .child_state {
            color: #000 !important;
            background: none !important;
            border: 1px solid #000 !important;
          }
        }
      }

    }
  }
}