.general_table {
  width: 100%;
  height: 100%;

  &_head {
    tr {
      th {
        padding: 8px 10px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.6);
        text-align: left;
      }
    }
  }

  &_body {
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      border-radius: 6px;
      background: rgba($color: #fff, $alpha: 0.05);
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 6px;
      background: #F0FDFF;
    }


    .table_body_content {
      width: 100%;
      border-bottom: 1px solid;
      background: #30507b;
      border-color: rgba(10, 41, 97, 0.8);
      cursor: pointer;

      &:nth-child(odd) {
        background: #29446c;
      }

      &>td {
        padding: 8px 10px;
        font-size: 14px;
        font-family: OPPOSans-M;
        color: #fff;

        &:nth-child(2) {
          font-family: OPPOSans-R;
          color: rgba($color: #fff, $alpha: 0.8);
        }


      }

      .state_box {
        min-width: 44px;
        height: 20px;
        padding: 0 4px;
        border-radius: 2px;
        border: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }

    .content {
      &_success {
        .state_box {
          background: rgba(95, 207, 136, 0.15);
          border-color: #5FCF88;
        }

        &:hover {
          background: #5fcf88;
        }
      }

      &_waring {
        .state_box {
          background: rgba(247, 203, 125, 0.15);
          border-color: #F7CB7D;
        }

        &:hover {
          background: #F7CB7D;
        }
      }

      &_success,
      &_waring {
        &:hover {
          .state_box {
            color: #000000;
            border-color: #000000;
          }

          &>td {
            color: #000;

            &:nth-child(2) {
              color: #000 !important;
            }
          }
        }

      }
    }

    .active_content {
      &_success {
        background: #5fcf88 !important;

      }

      &_waring {
        background: #F7CB7D !important;
      }

      &_success,
      &_waring {
        .state_box {
          color: #000000;
          border-color: #000000;
        }

        &>td {
          color: #000;

          &:nth-child(2) {
            color: #000 !important;
          }
        }
      }
    }



  }

  .scroll_head {
    width: calc(100% - 1em);

    &>tr {
      width: 100%;
      display: table;
      table-layout: fixed;

      &>th {
        &:last-child {
          width: 6px;
          padding: 0;
        }
      }
    }
  }

  .scroll_body {
    display: block;

    .table_body_content {
      display: table;
      table-layout: fixed;
    }
  }
}

.general_title {
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .title_content {
    width: 100%;
    height: 14px;
    margin-bottom: 8px;
    font-family: OPPOSans-R;
    font-weight: normal;
    color: rgba($color: #fff, $alpha: 0.6);
    line-height: 14px;
  }

  .list_box {
    width: 100%;
    min-height: 30px;
    max-height: 309px;

    .general_list {

      .list_left {
        height: 14px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 14px;
      }

      .list_right {
        width: 156px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>div {
          &:nth-child(1) {
            height: 14px;
            font-size: 14px;
            font-family: OPPOSans-R;
            font-weight: normal;
            color: #FFFFFF;
            line-height: 14px;
          }
        }

        .state_box {
          width: 58px;
          height: 20px;
          border-radius: 2px;
          border: 1px solid;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .success {
          background: rgba(95, 207, 136, 0.15);
          border-color: #5FCF88;
        }

        .warning {
          background: rgba(247, 203, 125, 0.15);
          border-color: #F7CB7D;
        }
      }

    }
  }
}

.general_personnel {
  width: 100%;
  padding: 10px;
  background: rgba($color: #50789C, $alpha: 0.5);

  .personnel_top {
    width: 100%;
    display: flex;

    &>img {
      width: 90px;
      height: 126px;
      border: 2px solid #FFFFFF;
      margin-right: 19px;
      background: #929292;
    }

    &>div {
      padding-top: 14px;

      .information_text {
        width: 250px;
        height: 14px;
        margin-bottom: 16px;
        font-size: 14px;
        font-family: OPPOSans-B;
        font-weight: normal;
        color: #fff;
        line-height: 14px;

        &>span {
          font-family: OPPOSans-R;
          color: rgba($color: #fff, $alpha: 0.6);
        }
      }
    }

  }

  .personnel_bottom {
    width: 100%;
    max-height: 100px;
    margin-top: 14px;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.35);
    padding-top: 10px;
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;
    color: rgba($color: #fff, $alpha: 0.8);
  }
}

.close_button {
  width: 26px;
  height: 26px;
  padding-top: 0 !important;
  border-radius: 50%;
  background: rgba(9, 25, 54, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .pt-icon {
    width: 12px;
    height: 12px;
    line-height: 12px;
    font-size: 12px;

  }
}


.general_list {
  width: 100%;
  min-height: 30px;
  padding: 8px 10px;
  margin-bottom: 1px;
  background: rgba($color: #50789C, $alpha: 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(odd) {
    background: rgba($color: #3F607E, $alpha: 0.5);
  }

  .state_box {
    min-width: 44px;
    height: 20px;
    padding: 0 4px;
    border-radius: 2px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .success {
    background: rgba(95, 207, 136, 0.15);
    border-color: #5FCF88;
  }

  .warning {
    background: rgba(247, 203, 125, 0.15);
    border-color: #F7CB7D;
  }


}

.general_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 968px;
  height: 78.24%;
  background: rgba(19, 40, 94, 0.8);
  border: 1px solid #22519C;
  backdrop-filter: blur(2px);
  z-index: 6;

  .modal_head {
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #22519D;
    font-size: 28px;
    font-family: OPPOSans-B;
    font-weight: normal;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .close_button {
      position: absolute;
      top: 16px;
      right: 16px;
      background: rgba($color: #fff, $alpha: 0.15);
    }
  }

  .modal_body {
    width: 100%;
    height: calc(100% - 124px);
  }

  .modal_footer {
    width: 100%;
    height: 64px;
    padding: 17px 24px;
    border-top: 1px solid #22519D;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer_left {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .footer_right {
      height: 100%;
      display: flex;
      align-items: center;

      .footer_button {
        width: 70px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #A7A8A7;
        margin-left: 8px;
        background: rgba(255, 255, 255, 0.15);
        font-size: 16px;
        font-family: OPPOSans-M;
        font-weight: normal;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .download {
        background: rgba(95, 207, 136, 0.15);
        border: 1px solid #5FCF88;
      }
    }
  }
}

.is_shrink_modal {
  transform: scale(0.3);
  top: 45%;
  left: 65%;
}

.select_box {
  width: 124px;
  height: 24px;
  background: rgba(13, 37, 99, 0.8);
  border-radius: 2px;
  border: 1px solid #3E7BD4;


  .ant-select {
    width: 100%;
    color: #fff;
    font-family: OPPOSans-R;
    font-weight: normal;

    .ant-select-arrow {
      color: #fff;
    }

    .ant-select-dropdown {
      background-color: rgba(13, 37, 99, 0.8);

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #02d281;
      }

      .ant-select-item {
        color: #fff;

        &:hover {
          background-color: rgba(0, 255, 203, 0.15);
        }
      }
    }
  }

}

.general_collapse {
  width: 100%;

  .collapse_head {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    background: rgba($color: #50789C, $alpha: 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .head_l {
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      font-family: OPPOSans-B;
      font-weight: normal;
      color: #fff;
    }

    .head_r {
      .pt-icon {
        color: #d8d8d8;
      }
    }
  }

  .collapse_body {
    width: 100%;
    max-height: 400px;


    .collapse_list {
      cursor: pointer;

      &:hover {
        .list_l {
          color: #000;
        }

        .state_box {
          color: #000;
          border-color: #000;
        }
      }

      .list_l {
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: #fff;
      }
    }

    .hover_success {
      &:hover {
        background: #5FCF88;
      }
    }

    .hover_warning {
      &:hover {
        background: #F7CB7D;
      }
    }

    .active_success,
    .active_warning {
      .list_l {
        color: #000;
      }

      .state_box {
        color: #000;
        border-color: #000;
      }
    }

    .active_success {
      background: #5FCF88;
    }

    .active_warning {
      background: #F7CB7D;
    }
  }
}