.statistical_box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66.67%;
  height: 64.81%;
  background: rgba(19, 40, 94, 0.8);
  border: 1px solid #22519C;
  backdrop-filter: blur(2px);
  z-index: 4;

  .statistical_head {
    width: 100%;
    padding: 17px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head_left {
      height: 22px;
      line-height: 22px;
      font-size: 22px;
      font-weight: normal;
      font-family: HYYakuHei55W;
      color: #fff;
    }

    .head_right {
      .close_button {
        background: rgba($color: #fff, $alpha: 0.15);
      }
    }
  }

  .charts_box {
    width: 100%;
    height: calc(242px - 60px);
    padding: 0 60px;
    border-bottom: 1px solid #22519C;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .chart_content {
      position: relative;
      display: flex;
      align-items: center;

      .total_box {
        position: absolute;
        top: 26px;
        left: 33px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .number {
          height: 36px;
          margin-bottom: 9px;
          line-height: 36px;
          font-size: 36px;
          font-family: OPPOSans-B;
          font-weight: normal;
          color: #fff;
        }

        .title {
          height: 16px;
          margin-bottom: 5px;
          line-height: 16px;
          font-size: 16px;
          font-family: OPPOSans-M;
          font-weight: normal;
          color: #fff;
        }

        .unit {
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: rgba($color: #fff, $alpha: 0.6);
        }
      }

      .details_box {
        margin-left: 25px;

        &>div {
          &:nth-child(1) {
            margin-bottom: 16px;
          }

          &>div {
            color: #fff;

            &:nth-child(1) {
              height: 24px;
              line-height: 24px;
              margin-bottom: 7px;
              font-size: 24px;
              font-family: OPPOSans-M;
              font-weight: normal;
              color: #fff;
            }

            &:nth-child(2) {
              height: 14px;
              line-height: 14px;
              font-size: 14px;
              font-family: OPPOSans-R;
              font-weight: normal;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .list_box {
    width: 100%;
    height: calc(100% - 242px);
    display: flex;
    align-items: center;

    .list_box_item {
      width: 33.33%;
      height: 100%;
      padding: 26px 23px;
      position: relative;

      &:nth-child(2) {
        border-left: 1px solid #22519C;
        border-right: 1px solid #22519C;
      }

      .item_head {
        width: 100%;
        height: 24px;
        margin-bottom: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .head_l {
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          font-family: OPPOSans-M;
          font-weight: normal;
          color: #fff;
        }

        .head_r {
          .pt-tab {
            width: 204px;
          }
        }
      }

      .item_body {
        width: 100%;
        height: calc(100% - 24px);

        .general_list {
          &>div {
            &:nth-child(1) {
              color: #fff;
              height: 14px;
              line-height: 14px;
              font-family: OPPOSans-R;
              font-weight: normal;
              color: #fff;
            }

            &:nth-child(2) {
              display: flex;
              align-items: center;
            }

          }

          .title {
            min-width: 190px;
          }

          .time {
            width: 75px;
            height: 14px;
            line-height: 14px;
            margin-right: 16px;
            font-size: 14px;
            font-family: OPPOSans-R;
            font-weight: normal;
            color: #fff;
          }


        }
        &.inspection_body {
          .general_list{
            .time {
              margin-right: 0;
              white-space: nowrap;
            }
            .title {
              min-width: 120px;
            }
          }
        }
      }
    }
  }

  .ant-tree {
    .ant-tree-node-content-wrapper {
      .ant-tree-title {
        .tree_title_box {
          width: 100%;
          padding: 5px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item_l {
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            font-family: OPPOSans-R;
            font-weight: normal;
            color: #fff;
          }

          .item_r {
            min-width: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.export {
              background: rgba(3, 31, 82, 0.8);
              border: 1px solid #0352A2;
              text-align: center;
              display: flex;
              justify-content: center;
            }

            .state_box {
              min-width: 44px;
              height: 20px;
              padding: 0 4px;
              border-radius: 2px;
              border: 1px solid;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;

              &:last-child {
                margin-left: 10px;
              }
            }

            .success {
              background: rgba(95, 207, 136, 0.15);
              border-color: #5FCF88;
            }

            .warning {
              background: rgba(247, 203, 125, 0.15);
              border-color: #F7CB7D;
            }

            .error {
              background: rgba(255, 58, 58, 0.15);
              border-color: #ff3a3a;
            }
          }
        }
      }
    }

  }
}

.export_btn {
  cursor: pointer;
  color: #fff;
  padding-bottom: 4px;
}

.export_btn2 {
    padding: 4px 6px;
    background: rgba(3, 31, 82, 0.8);
    border: 1px solid #0352A2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff;
    font-size: 14px;
    font-family: OPPOSans-R;
    z-index: 3;
    width: fit-content;
    cursor: pointer;
    float: right;
}