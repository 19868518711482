.pt-icon {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  color: #ffffff;

  svg {
    width: 100%;
    height: 100%;
    fill: currentcolor;
  }
}

.pt-menu {
  height: 22px;
  color: #fff;

  &__router-list {
    display: flex;

    .ant-popover {
      &-inner {
        &-content {
          color: #fff;
          font-size: 16px;
          font-family: OPPOSans-R;
          font-weight: normal;

          &>div {
            width: 64px;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
          }
        }
      }
    }

    .router__content {
      position: relative;
      min-width: 100px;
      margin-left: 20px;
      font-size: 22px;
      font-family: HYYakuHei;
      font-weight: normal;
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #00beff;

        .pt-icon {
          color: #00beff;
        }
      }

      .pt-icon {
        width: 30px;
        height: 30px;
        margin-bottom: 14px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.pt-drawer {
  top: 0 !important;
  padding: 135px 0 0 0;
  overflow: hidden !important;
  box-shadow: none;
  font-weight: normal;
  z-index: 3 !important;
  user-select: none;

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
    height: auto !important;
    pointer-events: auto;

    .ant-drawer-content {
      background: none;

      .ant-drawer-body {
        padding: 0 0 0 40px;
        overflow: hidden;
      }
    }
  }

  &--left,
  &--right {
    pointer-events: none;
  }
}

.pt-drawer--right {
  padding: 135px 30px 0 0;

  .ant-drawer-body {
    padding: 0 40px 0 0 !important;
    overflow: hidden;
  }
}

.pt-card {
  width: 100%;
  margin-bottom: 24px;
  backdrop-filter: blur(3px);
  position: relative;
  z-index: 1;
  &.z-2{
    z-index: 2;  
  }
  .topFont4 {
    background: url('../../assets/img/topfont4.png') no-repeat;
  }

  .topFont5 {
    background: url('../../assets/img/topfont5.png') no-repeat;
  }

  .topFont6 {
    background: url('../../assets/img/topfont6.png') no-repeat;
  }

  .topFont7 {
    background: url('../../assets/img/topfont7.png') no-repeat;

    .pt-card__head--left {
      margin-left: 14px;

      &>div {
        &:nth-child(1) {
          width: 180px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  &__head {
    height: 47px;
    display: flex;
    padding-top: 5px;
    background-size: 100% 100% !important;
    justify-content: space-between;
    align-items: flex-start;

    &--left {
      margin-left: 14px;

      &>div {
        &:nth-child(1) {
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          font-family: HYYakuHei;
          font-weight: normal;
          color: #ffffff;
        }
      }
    }

    &>div:nth-child(2) {
      padding-top: 5px;
    }

    .newBox {
      display: flex;
      justify-content: center;
      width: 172px;
      height: 20px;
      background: rgba(3, 38, 103, 0.8);
      border-radius: 2px;
      border: 1px solid #207DDB;
    }

    .card_text {
      height: 26px;
      padding: 5px;
      color: #fff;
      font-weight: 400;
      color: #ffffffcc;

      display: flex;
      justify-content: center;
      align-items: center;

      .text_num {
        height: 18px;
        line-height: 18px;
        font-size: 20px;
        font-family: Arial;
        font-weight: normal;
        color: #ffffff;
      }

      .text_unit {
        height: 14px;
        font-size: 12px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: #ffffffcc;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }

  &__content {
    position: relative;
    padding: 0 18px 12px;
    background: rgba(10, 41, 97, 0.8);
    border: 1px solid #0352A2;
    border-top: none;

    &::before {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background:
        linear-gradient(rgba(37, 181, 255, 1), rgba(37, 181, 255, 1)) left bottom,
        linear-gradient(rgba(37, 181, 255, 1), rgba(37, 181, 255, 1)) left bottom,
        linear-gradient(rgba(37, 181, 255, 1), rgba(37, 181, 255, 1)) right bottom,
        linear-gradient(rgba(37, 181, 255, 1), rgba(37, 181, 255, 1)) right bottom;
      background-repeat: no-repeat;
      background-size: 1px 12px, 12px 1px;
      content: '';
      pointer-events: none;
    }
  }
}

.pt-card-suffix {
  color: white;
  font-family: OPPOSans-R;

  span {
    &.value {
      margin-right: 4px;
      width: 10px;
      height: 15px;
      font-size: 15px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: #ffffff;
      line-height: 15px;
    }

    opacity: 0.6;
  }
}

.pt-progress {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: OPPOSans-R;
    color: #ffffff;

    &>div:nth-child(1) {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .stripes {
    height: 16px;
    margin-top: 6px;
    padding: 0 6px;
    background: rgba($color: #43799F, $alpha: 0.5);
    display: flex;
    align-items: center;

    .stripes-active {
      position: relative;
      border-radius: 16px;

      @keyframes mymove {
        0% {
          width: 0;
          background: rgba($color: #fff, $alpha: 0.5);
        }

        100% {
          width: 100%;
          background: rgba($color: #fff, $alpha: 0);
        }
      }

      @-webkit-keyframes mymove {
        0% {
          width: 0;
          background: rgba($color: #fff, $alpha: 0.5);
        }

        100% {
          width: 100%;
          background: rgba($color: #fff, $alpha: 0);
        }
      }

      &::after {
        position: absolute;
        height: 6px;
        left: 0;
        top: 0;
        border-radius: 16px;
        content: "";
        animation: mymove 1.2s linear infinite;
        -webkit-animation: mymove 1.2s linear infinite;
      }
    }

    &>div {
      height: 6px;
    }
  }
}

.pt-tab {
  display: flex;
  justify-content: space-around;
  height: 20px;
  line-height: 18px;
  color: rgb(255, 255, 255);
  background: rgba(3, 38, 103, 0.8);
  border-radius: 2px;
  border: 1px solid #207DDB;

  &__item {
    padding: 0 8px;
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;
    text-align: center;
    cursor: pointer;

    &.active {
      background: #50EDFF;
      color: rgba(0, 0, 0, 1);
    }

    .title {
      font-family: OPPOSans-R;
      font-weight: normal;
    }

    &.fill {
      width: 100%;
      text-align: center;
    }

    &.disabled {
      color: #8c8c8c;
      cursor: not-allowed;
    }
  }
}

.pt-small-circle-tab {
  display: flex;
  align-items: center;
  justify-content: center;

  .pt-small-circle {
    color: rgba($color: #fff, $alpha: 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .point {
      margin-right: 4px;
      width: 8px;
      height: 8px;
      border: 1px solid;
      border-radius: 50%;
      background-color: transparent;
    }

    +.pt-small-circle {
      margin-left: 10px;
    }
  }
}

.pt-child-title {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--left {
    font-size: 16px;
    font-family: OPPOSans-R;
    font-weight: bold;
    color: #fff;
  }
}

.pt-color-legend {
  display: flex;
  align-items: center;

  &__box {
    width: 20px;
    height: 10px;
    margin-right: 6px;
    border-radius: 2px;
  }

  &__text {
    font-size: 12px;
    font-family: OPPOSans-R;
    font-weight: normal;
    color: #fff;
  }
}

.pt-icon-legend {
  width: 121px;
  height: 56px;
  padding: 10px 8px;
  border-radius: 2px;
  background: rgba($color: #43799F, $alpha: 0.5);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &>div {
    height: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &>span {
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: rgba($color: #fff, $alpha: 0.8);
    }

    .pt-icon {
      color: #fff;
    }

    .scale {
      font-size: 12px !important;
      transform: scale(0.24);
    }
  }
}

.pt-control-card {
  width: 100%;
  padding: 10px 6px;
  margin-bottom: 6px;
  background: rgba(29, 34, 44, 0.6);
  border-radius: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .content__title {
      width: 100%;
      height: 14px;
      line-height: 14px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.6);
      }

      div {
        width: 48px;
        height: 20px;
        border-radius: 2px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .content__list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.pt-tag {
  width: 58px;
  height: 20px;
  line-height: 18px;
  margin: 6px 3px 0 0;
  border-radius: 2px;
  border: 1px solid #6d7278;
  font-size: 14px;
  font-family: OPPOSans-R;
  font-weight: normal;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.pt-list-table {
  width: 100%;
  max-height: 680px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: #F0FDFF;
  }

  &--unfold {
    width: 100%;
    height: 30px;
    padding: 0 10px 0 6px;
    margin-bottom: 1px;
    background: rgba(34, 39, 49, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba($color: #303745, $alpha: 0.6);
    }

    .unfold--left {
      color: rgba($color: #fff, $alpha: 0.8);
      display: flex;
      align-items: center;

      .pt-icon {
        margin-right: 4px;
        color: rgba($color: #fff, $alpha: 0.8);
      }
    }

    .unfold--right {
      color: rgba($color: #fff, $alpha: 0.6);
    }

    .unfold--left,
    .unfold--right {
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
    }
  }

  &--text {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    margin-bottom: 1px;
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;
    color: rgba($color: #fff, $alpha: 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:nth-child(odd) {
      background: rgba(29, 34, 44, 0.6);
    }

    &:nth-child(even) {
      background: rgba(35, 42, 57, 0.6);
    }

    &>div {
      width: 44px;
      height: 20px;
      line-height: 18px;
      border-radius: 2px;
      text-align: center;
    }

    .normal {
      background: rgba(2, 210, 129, 0.15);
      border: 1px solid #02d281;
    }

    .abnormal {
      background: rgba(255, 69, 69, 0.15);
      border: 1px solid #ff4545;
    }
  }

  .active--text {
    &--normal {
      color: #000;
      background: #05c97d;
    }

    &--abnormal {
      color: #000;
      background: #ff4545;
    }

    &--normal,
    &--abnormal {
      &>div {
        background: #00000000;
        border: 1px solid #000;
      }
    }
  }
}

.pt-basic-information {
  width: 100%;
  max-height: 70.5vh;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #F0FDFF;

  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }

  .document__box {
    display: flex;
    align-items: center;

    .box--left {
      .icon-wenjianicon {
        color: #ff4545;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .icon-xiazaiicon1 {
      color: #0091ff;
      cursor: pointer;
    }
  }

  .assign-button {
    width: 100%;
    height: 30px;
    border: 1px solid #ffc96f;
    margin-top: 8px;
    line-height: 28px;
    font-size: 14px;
    font-family: OPPOSans-M;
    font-weight: normal;
    color: #ffffff;
    background: rgba(255, 201, 111, 0.15);
    text-align: center;
    cursor: pointer;
  }
}

.pt-maintain-records {
  .date-picker {
    width: 106px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #6d7278;
    display: flex;
    align-items: center;
    overflow: hidden;

    .ant-picker-input {
      &>input {
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.8);
      }
    }

    .ant-picker-suffix {
      color: #d8d8d8;
    }
  }

  &__list {
    width: 100%;
    max-height: 694px;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      border-radius: 6px;
      background: rgba($color: #fff, $alpha: 0.05);
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 6px;
      background: #F0FDFF;
    }
  }
}

.pt-maintain-table {
  width: 100%;
  border: 1px solid #05c97d;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.pt-table__line {
  width: 100%;
  // min-height: 30px;
  padding: 5px 10px;
  margin-bottom: 1px;

  .line--top {
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .line--left {
      line-height: 20px;
      color: rgba($color: #fff, $alpha: 0.8);
    }

    .line--right {
      max-width: 70%;
      line-height: 20px;
      color: rgba($color: #fff, $alpha: 1);

      &__text {
        width: 275px;
        line-height: 20px;
        color: #00ffcb;
      }
    }

    .show_image_box {
      width: 100%;
      height: 200px;
      margin-top: 4px;

      img {
        width: 100%;
        height: 100%;

      }
    }

    .monitoring {
      display: flex;

      .monitoring_number {
        color: #fff;
      }

      .monitoring_show {
        width: 50px;
        height: 20px;
        margin-left: 10px;
        border-radius: 3px;
        color: #fff;
        background: #0091ff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .line--bottom {
    padding-top: 17px;
    border-top: 1px solid #3d4043;
    margin-top: 10px;
  }

  .line__head {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;

    &>div {
      &:nth-child(1) {
        color: rgba($color: #fff, $alpha: 0.8);
      }
    }
  }

  .stations {
    &--left {
      display: flex;
      align-items: center;

      &>div {
        &:nth-child(1) {
          position: relative;
          width: 88px;
          font-size: 14px;
          font-family: OPPOSans-M;
          font-weight: normal;

          span {
            position: absolute;
            bottom: -1px;
            transform: scale(0.6);
            display: inline-block;
          }
        }

        &:nth-child(2) {
          font-size: 12px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: rgba($color: #fff, $alpha: 0.35);
          display: flex;
          align-items: center;
        }
      }

      .style1 {
        color: #00ffcb;
      }

      .style2 {
        color: #32c5ff;
      }

      .style3 {
        width: 120px !important;
        color: #00ffcb;
        font-size: 20px !important;
      }
    }

    &--right {
      display: flex;
      align-items: end;
      color: #fff;

      .right {
        font-family: OPPOSans-R;
        font-weight: normal;

        &__content {
          font-size: 14px;
          color: #fff;
          margin-right: 2px;
        }

        &__unit {
          font-size: 12px;
          color: rgba($color: #fff, $alpha: 0.6);
        }
      }
    }
  }
  .stations-right {
    font-family: OPPOSans-R;
    font-weight: normal;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    .right_content {
      color: #fff;
      flex: 1;
      text-align: right;
    }
    .right_unit {
      flex: 0 0 150PX;
      color: #FFF;
      text-align: right;
      span {
        font-size: 14px;
        color: hsla(0, 0%, 100%, .6);
      }
    }
  }

  .pumpStation {
    &--right {
      display: flex;
      color: #fff;

      .right {
        font-family: OPPOSans-R;
        font-weight: normal;

        &__content {
          font-size: 14px;
          color: #fff;
          margin-right: 2px;
        }

        &__unit {
          font-size: 14px;
          color: rgba($color: #fff, $alpha: 0.6);
        }
      }
    }
  }

  .state-tag {
    width: 44px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
    margin-left: 14px;
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;
    text-align: center;
  }

  .normal {
    background: #02d281;
    box-shadow: 0px 0px 6px 0px rgba($color: #02d281, $alpha: 0.7);
  }

  .abnormal {
    background: #ff3d3d;
    box-shadow: 0px 0px 6px 0px rgba($color: #ff3d3d, $alpha: 0.7);
  }

  &:nth-child(odd) {
    background: rgba($color: #43799F, $alpha: 0.5);
  }

  &:nth-child(even) {
    background: rgba($color: #346181, $alpha: 0.5);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.text__line {
  padding: 4px 10px 4px;
  align-items: flex-start;
}

.pt-img-carousel {
  position: relative;
  width: 100%;
  height: 98px;
  margin-bottom: 9px;
  overflow: hidden;

  &__content {
    position: absolute;
    height: 98px;
    left: 0;
    top: 0;
    transition: 1s;
    display: flex;
    z-index: 1;

    &>div {
      width: 176px;
      height: 98px;
      border: 4px solid #8c8c8c;
      margin-right: 6px;

      img {
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .left__button {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -8px;
    transform: rotateZ(180deg);
    cursor: pointer;
    z-index: 2;
  }

  .right-button {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;
    cursor: pointer;
    z-index: 2;
  }

  .unActive {
    color: rgba($color: #fff, $alpha: 0.2);
    cursor: default;
  }
}

.pt-check-list {
  width: 310px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &>div {
    min-width: 58px;
    height: 20px;
    line-height: 18px;
    padding: 0 5px;
    border: 1px solid #6d7278;
    border-radius: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;
    color: rgba($color: #fff, $alpha: 0.8);
    text-align: center;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  .active {
    &--blue {
      color: #fff;
      background: rgba($color: #437ee9, $alpha: 0.15);
      border-color: #427ee9;
    }

    &--cyan {
      color: #fff;
      background: rgba($color: #44c5cf, $alpha: 0.15);
      border-color: #44c5cf;
    }

    &--yellow {
      color: #fff;
      background: rgba($color: #fdbf29, $alpha: 0.15);
      border-color: #f0bd35;
    }

    &--green {
      color: #fff;
      background: rgba($color: #4ad857, $alpha: 0.15);
      border-color: #40cf33;
    }

    &--pink {
      color: #fff;
      background: rgba($color: #de70d7, $alpha: 0.15);
      border-color: #cc2ab5;
    }
  }
}

.pt-control-button-list {
  width: 100%;
  height: 34px;
  padding: 0 6px;
  background: rgba($color: #43799F, $alpha: 0.5);
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>div {
    width: 120px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
    font-size: 14px;
    font-family: OPPOSans-R;
    font-weight: normal;
    background: #6d7278;
    color: rgba($color: #fff, $alpha: 0.8);
    text-align: center;
    cursor: pointer;
  }
}

.pt-search {
  width: 100%;
  margin-bottom: 8px;
  background: rgba(3, 38, 103, 0.8);
  border-radius: 2px;
  border: 1px solid #207DDB;

  .icon-chazhaoicon,
  .ant-input {
    color: rgba($color: #fff, $alpha: 0.6);
  }
}

.pt-content-display {
  width: 100%;
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }

  .content {
    &__head {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      margin-bottom: 1px;
      background: rgba($color: #43799F, $alpha: 0.5);
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .pt-icon {
        color: #d8d8d8;
      }
    }

    &--show {
      height: 100%;
      max-height: 629px;
      overflow: hidden auto;
      display: block;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        width: 6px;
        border-radius: 6px;
        background: rgba($color: #fff, $alpha: 0.05);
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 6px;
        background: #F0FDFF;
      }
    }

    &--hide {
      height: 0;
      display: none;
    }
  }
}

.pt-content-progress {
  width: 100%;

  .progress {
    &__head {
      width: 100%;
      height: 14px;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .head {
        &--left {
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: rgba($color: #ffffff, $alpha: 0.8);
        }

        &--right {
          font-size: 14px;
          font-family: OPPOSans-M;
          font-weight: normal;
        }
      }
    }

    &__box {
      width: 100%;
      height: 8px;
      background: rgba($color: #fff, $alpha: 0.04);
      border-radius: 4px;
      overflow: hidden;

      &>div {
        height: 8px;
        border-radius: 4px;
      }
    }
  }
}