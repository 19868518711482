.pipeline {
  &__legend {
    .icon-legend__list {
      margin-bottom: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .alert-card {
        width: 121px;
        height: 56px;
        border-radius: 2px;
        background: rgba($color: #43799F, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        &>span {
          margin-right: 10px;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: #ff4545;
        }
      }
    }

    .color-legend__list {
      width: 100%;
      height: 33px;
      padding: 0 13px;
      background: rgba($color: #43799F, $alpha: 0.5);
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__control {
    .pt-tag {

      &:nth-child(6),
      &:nth-child(12) {
        margin-right: 0;
      }
    }

    .remove-style {
      margin-top: 0;
    }

    .tag {
      &--blue {
        background: rgba($color: #437ee9, $alpha: 0.15);
        border-color: #437fe8;
      }

      &--cyan {
        background: rgba($color: #44c5cf, $alpha: 0.15);
        border-color: #44c5cf;
      }

      &--yellow {
        background: rgba($color: #fdbf29, $alpha: 0.15);
        border-color: #f0bd35;
      }

      &--green {
        background: rgba($color: #4ad857, $alpha: 0.15);
        border-color: #40cf33;
      }

      &--pink {
        background: rgba($color: #de70d7, $alpha: 0.15);
        border-color: #cc2ab5;
      }
    }
  }

  &__list {
    &__menu {
      width: 100%;

      .menu-item {
        width: 100%;
        min-height: 30px;
        padding: 0 10px 0 6px;
        margin-bottom: 1px;
        background: rgba(34, 39, 49, 0.6);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &--left {
          color: rgba($color: #fff, $alpha: 0.8);
          display: flex;
          align-items: center;

          .pt-icon {
            margin-right: 4px;
            color: rgba($color: #fff, $alpha: 0.8);
          }
        }

        &--right {
          color: rgba($color: #fff, $alpha: 0.6);
        }

        &--left,
        &--right {
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
        }
      }
    }

    .pipeline_table {
      width: 100%;
      min-height: 70px;
      max-height: 680px;
      overflow: hidden auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        width: 6px;
        border-radius: 6px;
        background: rgba($color: #fff, $alpha: 0.05);
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 6px;
        background: #F0FDFF;
      }

      .table_line {
        width: 100%;
        min-height: 30px;
        margin-bottom: 2px;

        .table_line_title {
          width: 100%;
          height: 30px;
          padding: 8px 10px;
          margin-bottom: 2px;
          background: rgba($color: #43799F, $alpha: 0.5);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .title_l {
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            font-family: OPPOSans-M;
            font-weight: normal;
            color: #fff;

            span {
              font-family: OPPOSans-R;
              color: rgba($color: #fff, $alpha: 0.6);
            }
          }
        }

        .line_child_list {
          min-height: 6px;
          max-height: 300px;
          overflow: hidden auto;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            width: 6px;
            border-radius: 6px;
            background: rgba($color: #fff, $alpha: 0.05);
          }

          &::-webkit-scrollbar-thumb {
            width: 6px;
            border-radius: 6px;
            background: #F0FDFF;
          }

          .child_content {
            width: 100%;
            height: 30px;
            padding: 6px 10px;
            margin-bottom: 2px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:nth-child(odd) {
              background: rgba(67, 121, 159, 0.5);
            }

            &:nth-child(even) {
              background: rgba(52, 97, 129, 0.5);
            }

            .child_title {
              font-size: 14px;
              font-family: OPPOSans-R;
              font-weight: normal;
              color: #fff;
            }
          }

          .active {
            background: #05c97d !important;

            .child_title {
              color: #000;
            }
          }
        }
      }

    }
  }
}