.pt-sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  &--left {
  }

  &--right {
    display: flex;
    align-items: center;
    & > span {
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: rgba($color: #fff, $alpha: 0.8);
    }
    .button-box {
      display: flex;
      &__child {
        width: 28px;
        height: 28px;
        margin-right: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.12);
        }
      }
      .active {
        background: rgba(255, 255, 255, 0.12);
      }
    }
  }
}
