.pump-station {
  &__legend {
    .icon-legend__list {
      margin-bottom: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .alert-card {
        width: 121px;
        height: 56px;
        border-radius: 2px;
        background: rgba($color: #43799F, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        & > span {
          margin-right: 10px;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: #ff4545;
        }
      }
    }
    .color-legend__list {
      width: 100%;
      height: 33px;
      padding: 0 13px;
      background: rgba($color: #43799F, $alpha: 0.5);
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__list {
    &__menu {
      width: 100%;
      .menu-item {
        width: 100%;
        min-height: 30px;
        padding: 0 10px 0 6px;
        margin-bottom: 1px;
        background: rgba(34, 39, 49, 0.6);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &--left {
          color: rgba($color: #fff, $alpha: 0.8);
          display: flex;
          align-items: center;
          .pt-icon {
            margin-right: 4px;
            color: rgba($color: #fff, $alpha: 0.8);
          }
        }
        &--right {
          color: rgba($color: #fff, $alpha: 0.6);
        }
        &--left,
        &--right {
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
        }
      }
    }
  }
}
