.flow_chart_modal {
  height: 676px;

  .flow_chart_box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 532px;
    }
  }
}