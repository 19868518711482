.monitor_model {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 968px;
  height: 600px;
  background: rgba(19, 40, 94, 0.8);
  border: 1px solid #22519C;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 6;

  .model_head {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    border-bottom: 1px solid #22519C;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head_title {
      font-size: 18px;
      font-family: OPPOSans-M;
      color: #fff;
    }
  }

  .model_body {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .body_l {
      width: 200px;
      height: 100%;
      // padding: 10px;
      border-right: 1px solid #22519C;

      .left_body {
        width: 100%;
        height: 100%;
        overflow: hidden auto;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          width: 3px;
          border-radius: 3px;
          background: rgba($color: #fff, $alpha: 0.05);
        }

        &::-webkit-scrollbar-thumb {
          width: 3px;
          border-radius: 3px;
          background: #F0FDFF;
        }

        .left_body_child {
          width: 100%;
          height: 30px;
          padding: 0 10px;
          font-family: OPPOSans-R;
          color: #fff;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .active_child {
          background: #5FCF88;
          color: #000;
        }
      }
    }

    .body_r {
      position: relative;
      width: calc(100% - 200px);
      height: 100%;
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;

      &>span {
        position: absolute;
        color: #fff;
        font-size: 26px;
        font-family: OPPOSans-R;
      }
    }
  }
}