.pt-village {
  &__body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;

      @keyframes rotation1 {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      @-webkit-keyframes rotation1 {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .pt-svg-circle {
        animation: rotation1 12s linear infinite;
        -webkit-animation: rotation1 12s linear infinite;
      }

      .point-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 58px;
        height: 58px;
        transform: translate(-50%, -50%);

        svg {
          color: rgba(216, 216, 216, 0.2);
        }
      }


      .bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: radial-gradient(rgba(147, 158, 163, 0),
            rgba(147, 158, 163, 0.35));
      }

      .water {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        color: #eee;
      }
    }

    &--right {
      .village-info {
        display: flex;
        flex-direction: row;
        height: 18px;
        line-height: 18px;
        font-family: OPPOSans-M;
        font-weight: normal;
        color: #ffffff;

        &:nth-child(n + 2) {
          margin-top: 11px;
        }

        .dateInfo {
          width: 200px;
          height: 24px;
          margin-left: -4px;
          display: flex;
          align-items: center;

          .cornerLine {
            //margin-bottom: -8px;
            width: 24px;
            height: 1px;
            background-color: #43799F;
            transform: rotate(45deg) translateX(4px) translateY(2px);
            -webkit-transform: rotate(45deg) translateX(4px) translateY(2px);
          }

          .border {
            display: flex;
            align-items: center;
            justify-content: space-between;
            //margin-left: 15px;
            padding-bottom: 3px;
            width: 100%;
            height: 24px;
            border-bottom: 1px solid #43799F;

            .title {
              height: 14px;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              color: #ffffffcc;
              opacity: 35;
              line-height: 14px;
              margin-top: 3px;
              margin-left: 5px;
            }

            .flex-center {
              display: flex;
              align-items: flex-end;

              .number {
                font-size: 20px;
                height: 18px;
                line-height: 18px;
                font-family: Arial;
                font-weight: normal;
                color: #ffffff;
              }

              .unit {
                width: 20px;
                height: 14px;
                font-size: 12px;
                font-family: OPPOSans-R;
                font-weight: normal;
                color: #ffffffcc;
                line-height: 14px;
                margin-left: 4px;
              }
            }
          }
        }
      }

      .colortag1 {
        margin-top: 5px;
        width: 15px;
        height: 4px;
        background: #00beff;
      }

      .colortag2 {
        @extend .colortag1;
        background: #00ffcb;
      }

      .colortag3 {
        @extend .colortag1;
        background: #02d273;
      }

      .colortag4 {
        @extend .colortag1;
        background: #ffc96f;
      }
    }
  }
}

.pt-opera-status {
  &__suffix {
    display: flex;

    .tabs {
      background: rgba(18, 18, 18, 0.15);
      border-radius: 2px;
      border: 1px solid #6d7278;
      display: flex;

      &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 4px;
        cursor: pointer;

        .title {
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
        }
      }
    }

    .dateSelect {
      width: 75px;
      height: 20px;
      background: rgba(3, 38, 103, 0.8);
      border-radius: 2px;
      border: 1px solid #207DDB;
      margin-left: 4px;

      .ant-select {
        width: 100%;
        height: 100%;
        color: #fff;

        &-single:not(.ant-select-customize-input) .ant-select-selector {
          height: 24px;
          padding: 0;
        }

        .ant-select-selection-item {
          padding-left: 5px !important;
          padding-right: 0 !important;
          line-height: 16px !important;
          height: 16px;
        }

        .ant-select-arrow {
          color: #d8d8d8;
          right: 4px;
        }

        .ant-select-dropdown {
          background: rgba(3, 38, 103, 0.8);

          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: rgba($color: #02d281, $alpha: 1);
            color: rgba(0, 0, 0, 0.85);
          }

          .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            background: rgba($color: #00ffcb, $alpha: 0.15);
          }

          .ant-select-item {
            color: #fff;
          }
        }
      }
    }
  }

  &__body {
    /*   display: flex;
         flex-direction: column;*/

    .dataArea {
      display: grid;
      grid-template-columns: repeat(7, 54px);

      .town {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2px;

        .graphics {
          width: 52px;
          height: 52px;
          border: 1px solid rgba(240, 240, 240, 0.15);
          border-radius: 50%;
          position: relative;

          .canvas {
            position: absolute;
            left: 1px;
            top: 1px;
            z-index: 2;
          }

          .img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 35px;
            height: 35px;

            svg {
              color: rgba(216, 216, 216, 0.25);
            }
          }

          &>div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: OPPOSans-B;
            font-weight: normal;
            color: #ffffff;
          }
        }

        .name {
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: rgba(255, 255, 255, 0.8);
          font-size: 13px;
          white-space: nowrap;
        }
      }
    }

    .lengend {
      display: flex;
      justify-content: center;
      margin: 8px auto 15px;
      padding: 0 10px;
      width: 172px;
      height: 20px;
      background: rgba(3, 38, 103, 0.8);
      border-radius: 2px;
      border: 1px solid #207DDB;
    }

    .title {
      display: flex;
      justify-content: space-between;

      &>div {
        &:nth-child(1) {
          height: 14px;
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: #ffffff;
          line-height: 14px;
        }

        &:nth-child(2) {
          height: 14px;
          font-family: OPPOSans-R, OPPOSans;
          font-weight: normal;
          color: #ffffff;
          line-height: 14px;
        }
      }
    }

    .stripes {
      height: 16px;
      background: rgba(109, 114, 120, 0.25);
      display: flex;
      align-items: center;
      margin-top: 6px;

      &>div {
        height: 6px;
        background: #02d281;
      }
    }
  }
}

.pt-progressbar-box {
  width: 100%;
  display: flex;
  flex-direction: column;

  .progressbar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .double-progressbar-style {
      margin-bottom: 14px;
      display: flex;
      position: relative;

      &:hover {
        .content {
          border: 1px solid #d8d8d8;
        }
      }

      &>div {
        margin-right: 1px;

        &:last-child {
          margin-right: 0;
        }
      }

      .numbering {
        width: 16px;
        height: 18px;
        background: #F6F6F6;
        font-size: 12px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: #000;
        line-height: 18px;
        text-align: center;
        overflow: hidden;
      }

      .notRanked {
        background: #6d7278;
      }

      .title {
        width: 46px;
        height: 18px;
        background: rgba($color: #43799F, $alpha: 0.5);
        font-size: 14px;
        font-family: "OPPOSans-R";
        font-weight: normal;
        color: #ffffff;
        line-height: 18px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .content {
        width: 118px;
        height: 18px;
        background: rgba($color: #43799F, $alpha: 0.5);
        padding: 3px 1px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &>div {
          height: 3px;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .notData {
          width: 2px;
          height: 6px;
          background: #6d7278;
        }
      }

      .popup {
        position: absolute;
        top: 12px;
        right: -12px;
        width: 150px;
        height: 82px;
        padding: 10px 8px;
        border-radius: 6px;
        background: rgba(10, 41, 97, 0.8);
        border: 1px solid #0352A2;
        z-index: 5;

        &>div {
          &:nth-child(1) {
            font-size: 14px;
            font-family: "OPPOSans-R";
            font-weight: normal;
            color: rgba($color: #fff, $alpha: 0.8);
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>div {
              &:nth-child(1) {
                width: 8px;
                height: 4px;
              }

              &:nth-child(2),
              &:nth-child(3) {
                font-size: 14px;
                font-weight: normal;
                font-family: "OPPOSans-R";
                color: rgba($color: #fff, $alpha: 0.8);
              }
            }
          }
        }
      }
    }

    .progressbar-active {
      .content {
        border: 1px solid #d8d8d8;
      }
    }
  }

  .pt-progress-button-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      min-width: 123px;
      height: 20px;
      padding: 2px 5px;
      margin: 0 3px;
      border: 1px solid;
      border-color: #6d7278;
      border-radius: 2px;
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div {
        &:nth-child(1) {
          width: 8px;
          height: 4px;
        }
      }

      .pt-icon {
        width: 14px;
        height: 11px;
        line-height: 11px;
        font-size: 11px;
      }
    }

    .active {}
  }
}

.transparent-table {
  .active {
    background-color: rgba(2, 210, 129, 1);
  }

  .ant-table {
    background: transparent !important;
  }

  .ant-table-body {
    overflow-y: auto !important;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track-piece {
      background: rgba(18, 23, 34, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(18, 23, 34, 0.82);
      border-radius: 5px;
    }
  }

  .ant-table-content,
  tbody {
    background: transparent !important;
    overflow: auto !important;
  }

  [class*="-empty"] {
    color: white;
  }

  table {
    border-spacing: 0px vh(3);

    th,
    td {
      padding: 0 !important;
      border: none !important;
    }

    thead {
      line-height: 30px;
      border-spacing: 0;
      margin: 0 !important;
      background: rgba($color: #43799F, $alpha: 0.5);

      tr {
        height: 30px;

        th {
          text-align: center !important;
          background: transparent;
          color: rgba(255, 255, 255, 0.6);
          font-size: 12px;
          padding: 2px 0;

          &:first-child {
            //border-bottom-left-radius: 4px;
          }

          &:last-child {
            //border-bottom-right-radius: 4px;
          }

          &.ant-table-cell-scrollbar {
            opacity: 0;
          }
        }
      }
    }

    tbody {
      color: rgba(255, 255, 255, 0.8);

      tr {
        height: 30px;
        text-align: center;

        &:nth-child(odd) {
          background: rgba($color: #43799F, $alpha: 0.5);
        }

        &:nth-child(even) {
          background: rgba($color: #346181, $alpha: 0.5);
        }

        cursor: pointer;

        &:hover>td {
          background: initial !important;
        }

        .table_title {
          width: 100%;
          padding: 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      tr.ant-table-row {
        td:last-child {
          span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            width: 44px;
            height: 20px;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.8);

            &[data-status="0"] {
              background: #d19900;
            }

            &[data-status="1"] {
              background: #339758;
            }
          }
        }
      }
    }
  }
}

.pt-minor-operating {
  &__list {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    &>div {
      width: 58px;
      margin-right: 6px;

      &:nth-child(6),
      &:nth-child(12),
      &:nth-child(18),
      &:nth-child(24),
      &:nth-child(30) {
        margin-right: 0;
      }
    }

    .pt-minor-operating--content {
      margin-bottom: 14px;

      .content__text {
        width: 100%;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: #ffffff;
        text-align: center;
      }
    }
  }

  &__lengend {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      position: relative;
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: #ffffff;

      &:first-child {
        margin-right: 30px;

        &::before {
          background: rgba(50, 197, 255, 0.15);
          border: 1px solid #32c5ff;
        }
      }

      &::before {
        position: absolute;
        left: -12px;
        top: 7px;
        width: 8px;
        height: 8px;
        background: rgba(0, 255, 203, 0.15);
        border: 1px solid #00ffcb;
        border-radius: 50%;
        content: "";
      }
    }
  }
}

.pt-pagination {
  width: 86px;
  height: 20px;
  margin-right: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>div {
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    .pt-icon {
      height: 12px;
      line-height: 12px;
      font-size: 12px;
    }
  }

  &--left {
    width: 25px;
    height: 20px;
    border-radius: 4px 0 0 4px;

    .pt-icon {
      transform: rotateY(180deg);
    }
  }

  &--content {
    height: 20px;
    padding: 0px 6px;
    font-size: 14px;
  }

  &--right {
    width: 25px;
    height: 20px;
    border-radius: 0 4px 4px 0;
  }

  &--left,
  &--right {
    cursor: pointer;
  }

  .unActive {
    .pt-icon {
      color: rgba($color: #000, $alpha: 0.5);
    }
  }
}

.pt-device__table {
  overflow: hidden auto;
  max-height: 700px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: #F0FDFF;
  }

  .table__line {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:nth-child(odd) {
      background: rgba($color: #43799F, $alpha: 0.5);
    }

    &:nth-child(even) {
      background: rgba($color: #346181, $alpha: 0.5);
    }

    &--active--normal {
      background: #05c97d !important;

      .table__line {
        &--left {
          color: #000;
        }

        &--right {
          .table__tag {
            color: #000 !important;
            background: none !important;
            border: 1px solid #000 !important;
          }
        }
      }
    }

    &--active--abnormal {
      background: #FFE400 !important;

      .table__line {
        &--left {
          color: #000;
        }

        &--right {
          .table__tag {
            color: #000 !important;
            background: none !important;
            border: 1px solid #000 !important;
          }
        }
      }
    }

    &--left {
      height: 14px;
      color: rgba($color: #fff, $alpha: 0.8);
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      line-height: 14px;
    }

    &--right {
      display: flex;
      align-items: center;

      .table__tag {
        height: 20px;
        padding: 0 8px;
        border-radius: 2px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-left: 8px;
        }
      }

      .normal {
        background: rgba(2, 210, 129, 0.15);
        border: 1px solid #02d281;
      }

      .abnormal {
        background: rgba(255, 228, 0, 0.15);
        border: 1px solid #FFE400;
      }

      .alarm {
        background: rgba(255, 69, 69, 0.15);
        border: 1px solid #fe4445;
      }
    }
  }
}

.record {
  &__head {
    width: 380px;
    height: 30px;
    padding: 0 10px 0 4px;
    background: rgba($color: #43799F, $alpha: 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div {
      &:nth-child(1) {
        display: flex;
        align-items: center;

        .record__tag {
          height: 20px;
          padding: 0 8px;
          margin-right: 6px;
          border-radius: 2px;
          border: 1px solid #6d7278;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: rgba($color: #fff, $alpha: 0.8);
          line-height: 18px;
          text-align: center;
          cursor: pointer;
        }

        .record__active__tag {
          background: rgba(67, 126, 233, 0.15);
          border: 1px solid #427ee9;
          color: rgba($color: #fff, $alpha: 1);
        }
      }

      &:nth-child(2) {}
    }
  }

  &__content {
    width: 100%;
    height: 550px;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      border-radius: 6px;
      background: rgba($color: #fff, $alpha: 0.05);
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 6px;
      background: #F0FDFF;
    }

    &__box {
      width: 100%;
      min-height: 124px;
      margin-bottom: 8px;
      border: 1px solid #6d7278;

      .record__state {
        width: 44px;
        height: 20px;
        color: #fff;
        font-family: OPPOSans-R;
        font-size: 14px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .record--green {
        background: rgba(2, 210, 129, 0.15);
        border: 1px solid #02d281;
      }

      .record--red {
        background: rgba(255, 69, 69, 0.15);
        border: 1px solid #ff4545;
        cursor: pointer;
      }
    }
  }
}

.expand__button {
  position: fixed;
  right: 447px;
  top: 240px;
  transform: translateY(-50%);
  width: 26px;
  height: 34px;
  background: #121722;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #6d7278;
  border-right: none;
  z-index: 6;

  .pt-icon {
    width: 8px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
  }

  &>div {
    position: relative;
    width: 100%;
    height: 100%;
    transform: rotate(180deg);

    .jiantou1,
    .jiantou2 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #5f6672;
    }

    .jiantou1 {
      right: 8px;
      opacity: 0.6;
    }

    .jiantou2 {
      right: 14px;
    }
  }
}

.pipeline__content {
  max-height: 700px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    border-radius: 6px;
    background: rgba($color: #fff, $alpha: 0.05);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background: #F0FDFF;
  }
}