.pt-home {
  .home-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg,
        rgba(0, 15, 29, 0.35) 0%,
        rgba(0, 15, 29, 0.0) 18%,
        rgba(0, 15, 29, 0) 51%,
        rgba(0, 15, 29, 0) 85%,
        rgba(0, 15, 29, 0.35) 100%);
    z-index: 3;
    pointer-events: none;
  }

  .legend-box {
    position: fixed;
    left: calc(50vw - 225px);
    bottom: 113px;
    width: 614px;
    height: 46px;
    padding: 10px 18px;
    background: rgba(3, 31, 82, 0.8);
    border: 1px solid #0352A2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #fff;
    font-size: 14px;
    font-family: OPPOSans-R;
    z-index: 3;

    &::before {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background: linear-gradient(#25b5ff, #25b5ff) left top,
        linear-gradient(#25b5ff, #25b5ff) left top,
        linear-gradient(#25b5ff, #25b5ff) right top,
        linear-gradient(#25b5ff, #25b5ff) right top,
        linear-gradient(#25b5ff, #25b5ff) left bottom,
        linear-gradient(#25b5ff, #25b5ff) left bottom,
        linear-gradient(#25b5ff, #25b5ff) right bottom,
        linear-gradient(#25b5ff, #25b5ff) right bottom;
      background-repeat: no-repeat;
      background-size: 1px 12px, 12px 1px;
      content: "";
      pointer-events: none;
    }

    &>span {
      color: rgba($color: #fff, $alpha: 0.6);
    }

    .legend-list {
      width: 542px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .special-content {
        img {
          margin-right: 6px;
        }
      }
    }

    .legend-content {
      min-width: 84px;
      height: 26px;
      padding: 6px;
      border-radius: 2px;
      margin-bottom: 4px;
      background: rgba($color: #fff, $alpha: 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        margin-left: 4px;
      }
    }
  }

  .village {
    width: 642px;
    height: 173px;
    flex-wrap: wrap;

    &-title {
      display: inline-block;
      height: 14px;
      line-height: 14px;
      color: rgba($color: #fff, $alpha: 0.6);
    }

    &-top {
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #0352A2;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-l {
        display: flex;

        .village-title {
          margin-top: 6px;
        }

        .list-content {
          width: 450px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          border-right: 1px solid #0352A2;

          .legend-content {
            margin-right: 4px;
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
              margin-bottom: 0;
            }
          }

          .legend_show {
            border: 1px solid #fff;
          }
        }
      }

      .top-r {
        .legend-content:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-bottom {
      width: 100%;
      display: flex;

      .list-content {
        width: 440px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 30px;

        .statistical-content {
          min-width: 53px;
          height: 34px;

          &>div {
            &:nth-child(1) {
              height: 14px;
              margin-bottom: 6px;
              line-height: 14px;
              font-size: 14px;
              font-family: OPPOSans-R;
              font-weight: normal;
              color: #fff;

              &>span {
                font-size: 12px;
                color: rgba($color: #fff, $alpha: 0.6);
              }
            }

            &:nth-child(2) {
              height: 14px;
              line-height: 14px;
              font-size: 14px;
              font-family: OPPOSans-H;
              font-weight: normal;
              color: #fff;
            }
          }
        }
      }
    }


  }
}