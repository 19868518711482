.pt_remotely {
  width: 100%;

  .permissions_box {
    width: 100%;

    .permissions_head {
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: bold;
      color: #fff;
    }

    .pt-table__line {
      width: 100%;
      height: 30px;
      padding: 5px 10px;
      margin-bottom: 1px;

      .line--left {
        line-height: 20px;
        color: rgba($color: #fff, $alpha: 0.8);
        // width: 60px;
        float: left;
      }

      .line--right {
        max-width: 70%;
        line-height: 20px;
        color: rgba($color: #fff, $alpha: 1);

        &__text {
          width: 150px;
          line-height: 20px;
          // color: #00ffcb;
          color: #bdbdbd;
          height: 24px;
          float: right;

          .btn_control {
            float: left;
            width: 30px;
            border: 1px solid #bdbdbd;
            border-radius: 5px;
            padding: 0 2px;
            font-size: 12px;
            margin: 0 2px;
            height: 24px;
            cursor: pointer;
          }

          .active {
            color: #00ffcb;
            border: 1px solid #00ffcb;
          }
        }
      }
    }



    .permissions_input {
      width: 100%;
      height: 32px;
      background: rgba(3, 38, 103, 0.8);
      border: 1px solid #0352A2;

      .ant-input {
        color: #fff;
        background: none;

        &:hover {
          background: none;
        }
      }
    }

    .verification_code_button {
      width: 100%;
      height: 32px;
      border: 1px solid #0352A2;
      border-left: 0px;
      background: rgba(3, 38, 103, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #fff;
      font-family: OPPOSans-R;
      cursor: pointer;
    }

  }
}