.general_collapse {
  .collapse_head {
    margin-bottom: 1px;
  }

  .collapse_body {
    max-height: 400px;

    .collapse_item {
      width: 100%;
      min-height: 30px;
      padding: 8px 10px;
      margin-bottom: 1px;
      background: rgba($color: #50789C, $alpha: 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:nth-child(odd) {
        background: rgba($color: #3F607E, $alpha: 0.5);
      }


      &:hover {
        .item_l {
          color: #000;
        }

        .item_r {
          .state_box {
            color: #000;
            background: none;
            border-color: #000;
          }
        }
      }

      .item_l {
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-family: OPPOSans-R;
        font-weight: normal;
        color: #fff;
      }

      .item_r {
        min-width: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .state_box {
          min-width: 44px;
          height: 20px;
          padding: 0 4px;
          border-radius: 2px;
          border: 1px solid;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;

          &:last-child {
            margin-left: 10px;
          }
        }

        .success {
          background: rgba(95, 207, 136, 0.15);
          border-color: #5FCF88;
        }

        .warning {
          background: rgba(247, 203, 125, 0.15);
          border-color: #F7CB7D;
        }

        .error {
          background: rgba(255, 58, 58, 0.15);
          border-color: #ff3a3a;
        }
      }
    }

    .hover_success {
      &:hover {
        background: #5FCF88;
      }
    }

    .hover_warning {
      &:hover {
        background: #F7CB7D;
      }
    }

    .active_success,
    .active_warning {
      .item_l {
        color: #000;
      }

      .item_r {
        .state_box {
          color: #000;
          background: none;
          border-color: #000;
        }
      }
    }

    .active_success {
      background: #5FCF88 !important;
    }

    .active_warning {
      background: #F7CB7D !important;
    }

  }
}

.ant-tree {
  background: rgba($color: #fff, $alpha: 0) !important;

  .ant-tree-node-content-wrapper {
    &:hover {
      background: #00000000 !important;
    }

    .ant-tree-title {
      font-size: 14px;
      font-family: OPPOSans-R;
      font-weight: normal;
      color: #fff;

      .tree_title {
        width: 100%;
        padding: 5px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          .item_l {
            color: #000;
          }

          .item_r {
            .state_box {
              color: #000;
              background: none;
              border-color: #000;
            }
          }
        }

        .item_l {
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          font-family: OPPOSans-R;
          font-weight: normal;
          color: #fff;
        }

        .item_r {
          min-width: 44px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .state_box {
            min-width: 44px;
            height: 20px;
            padding: 0 4px;
            border-radius: 2px;
            border: 1px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            white-space: nowrap;
            &:last-child {
              margin-left: 10px;
            }
          }

          .success {
            background: rgba(95, 207, 136, 0.15);
            border-color: #5FCF88;
          }

          .warning {
            background: rgba(247, 203, 125, 0.15);
            border-color: #F7CB7D;
          }

          .error {
            background: rgba(255, 58, 58, 0.15);
            border-color: #ff3a3a;
          }

          .out {
            background: rgba(109, 114, 120, 0.15);
            border-color: #6d7278;
          }
        }
      }

      .hover_success {
        &:hover {
          background: #5FCF88;
        }
      }

      .hover_warning {
        &:hover {
          background: #F7CB7D;
        }
      }

      .active_success,
      .active_warning {
        .item_l {
          color: #000;
        }

        .item_r {
          .state_box {
            color: #000;
            background: none;
            border-color: #000;
          }
        }
      }

      .active_success {
        background: #5FCF88 !important;
      }

      .active_warning {
        background: #F7CB7D !important;
      }
    }
  }

  .ant-tree-node-selected {
    background: #00000000 !important;
  }

  .ant-tree-switcher {
    color: #fff;
  }

}